import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Col, Row, Container } from 'reactstrap'

const FooterWrapper = styled.footer`
  overflow: hidden;
  padding-top: 15px;
  padding-bottom: 25px;
  margin-top: 3px;
  margin-left: 3px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-bottom: 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 20px;
  }
  @media (max-width: 767px) {
    justify-content: center;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 15px;
  }
  @media (max-width: 575px) {
    padding-bottom: 10px;
  }
`

const FooterContainer = styled(Container)`
  padding-left: 120px;
  padding-right: 120px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const FooterParticipantsList = styled(Row)`
  justify-content: flex-end;
  padding-bottom: 60px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-bottom: 40px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 40px;
  }
  @media (max-width: 767px) {
    justify-content: center;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 30px;
  }
  @media (max-width: 575px) {
    padding-bottom: 20px;
  }
`

const FooterParticipantsItem = styled(Col)`
  display: flex;
  align-items: flex-start;
  flex-grow: 0;
  padding-left: 0;
  padding-right: 0;
  @media (max-width: 767px) {
    padding-bottom: 10px;
  }
`

const FooterParticipantsItemTitle = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 20px;
  white-space: nowrap;
  padding-right: 5px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 18px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 15px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
`

const FooterParticipantsItemLogo = styled.div`
  display: inline-block;
  height: 100%;
  min-width: 55px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    min-width: 45px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    min-width: 45px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    min-width: 35px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    min-width: 30px;
  }
  @media (max-width: 575px) {
    min-width: 25px;
  }
`

const FooterCopyrightWrapper = styled(Row)``

const FooterCopyright = styled(Col)`
  ${(props) => props.theme.textColorBlackLight};
  text-align: justify;
`

const FooterCopyrightText = styled.p`
  font-size: 10px;
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 9px;
  }
  @media (max-width: 575px) {
    font-size: 8px;
  }
`

const FooterCopyrightComposition = styled.p`
  font-size: 9px;
  margin-bottom: 0.7rem;
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 8px;
  }
  @media (max-width: 575px) {
    font-size: 7px;
  }
`

const FooterCopyrightWarning = styled.p`
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0.8rem;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 13px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 12px;
  }
  @media (max-width: 575px) {
    font-size: 11px;
  }
`

const FooterCopyrightLicense = styled(FooterCopyrightComposition).attrs({
  "data-iframe-height": ""
})`
  font-weight: 700;
`

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        PatronOneImage: file(relativePath: { regex: "/patron_one.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 240, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        PatronTwoImage: file(relativePath: { regex: "/patron_two.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 240, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <FooterWrapper>
          <FooterContainer>
            <FooterParticipantsList>
              <FooterParticipantsItem>
                <FooterParticipantsItemTitle>
                  <span>Patroni akcji:</span>
                </FooterParticipantsItemTitle>
                <FooterParticipantsItemLogo>
                  <a
                    href="http://www.gwiazdanadziei.pl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img fluid={data.PatronOneImage.childImageSharp.fluid} />
                  </a>
                </FooterParticipantsItemLogo>
                <FooterParticipantsItemLogo>
                  <a
                    href="http://sos-wzw.pl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img fluid={data.PatronTwoImage.childImageSharp.fluid} />
                  </a>
                </FooterParticipantsItemLogo>
              </FooterParticipantsItem>
            </FooterParticipantsList>
            <FooterCopyrightWrapper>
              <FooterCopyright>
                <FooterCopyrightText>
                  Copyright © 2018 -2020 Sanofi-Aventis Sp z o.o. Wszelkie prawa
                  zastrzeżone.
                  <br /> Strona jest skierowana do osób zamieszkujących
                  terytorium Polski i podlegających lokalnemu prawu.
                  <br /> Opracowano na podstawie Charakterystyk Produktów
                  Leczniczych z datą 02/2019.
                </FooterCopyrightText>
                <FooterCopyrightComposition>
                  Essentiale Forte, 300 mg, kapsułki. 1 kapsułka zawiera: 300 mg
                  (Phospholipidum essentiale) fosfolipidów z nasion sojowych
                  zawierających (3-sn-fosfatydylo)cholinę. Substancje pomocnicze
                  o znanym działaniu: olej sojowy, etanol. Wskazania do
                  stosowania: Essentiale Forte jest roślinnym produktem
                  leczniczym stosowanym w chorobach wątroby. Zmniejsza
                  subiektywne dolegliwości, takie jak: brak apetytu, uczucie
                  ucisku w nadbrzuszu spowodowane uszkodzeniem wątroby w wyniku
                  nieprawidłowej diety, działania substancji toksycznych lub w
                  przebiegu zapalenia wątroby. Przeciwwskazania: Nadwrażliwość
                  na substancję czynną, soję, orzeszki ziemne lub na
                  którąkolwiek substancję pomocniczą. Podmiot odpowiedzialny:
                  Sanofi-Aventis Sp. z o.o.
                  <br /> Essentiale MAX, 600 mg, kapsułki, twarde. Każda
                  kapsułka zawiera: Phospholipidum essentiale, fosfolipidy z
                  nasion sojowych zawierające (3-sn-fosfatydylo)cholinę, 600 mg.
                  Substancje pomocnicze o znanym działaniu: olej sojowy.
                  Wskazania do stosowania: Essentiale Max jest roślinnym
                  produktem leczniczym stosowanym w chorobach wątroby. Zmniejsza
                  subiektywne dolegliwości, takie jak: brak apetytu, uczucie
                  ucisku w prawym nadbrzuszu spowodowane uszkodzeniem wątroby w
                  wyniku nieprawidłowej diety, działania substancji toksycznych
                  lub w przebiegu zapalenia wątroby. Przeciwwskazania:
                  Nadwrażliwość na substancję czynną, soję, orzeszki ziemne lub
                  na którąkolwiek substancję pomocniczą. Podmiot odpowiedzialny:
                  Sanofi-Aventis Sp. z o.o.
                </FooterCopyrightComposition>
                <FooterCopyrightWarning>
                  Przed użyciem zapoznaj się z ulotką, która zawiera wskazania,
                  przeciwwskazania, dane dotyczące działań niepożądanych i
                  dawkowanie oraz informacje dotyczące stosowania produktu
                  leczniczego, bądź skonsultuj się z lekarzem lub farmaceutą,
                  gdyż każdy lek niewłaściwie stosowany zagraża Twojemu życiu
                  lub zdrowiu.
                </FooterCopyrightWarning>
                <FooterCopyrightLicense>
                  SAPL.PCH.20.01.0041
                </FooterCopyrightLicense>
              </FooterCopyright>
            </FooterCopyrightWrapper>
          </FooterContainer>
        </FooterWrapper>
      )
    }}
  />
)

export default Footer
