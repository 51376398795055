import styled from 'styled-components'
import { Container, Row, Col, CardBody, Card } from 'reactstrap'

import downloadIcon from '../images/download_icon.svg'

export const MainContainer = styled(Container)`
  padding-left: 120px;
  padding-right: 120px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

export const PollStageWrapper = styled(Row)``

export const PollStageCol = styled(Col)`
  ${(props) =>
    props.stage >= 2 &&
    `
    padding-bottom: 80px;
  `}
  @media (min-width: 1200px) and (max-width: 1599px) {
    ${(props) =>
      props.stage >= 2 &&
      `
      // flex: 0 0 58.333333%;
      // max-width: 58.333333%;
      // padding-bottom: 50px;
      `}
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    ${(props) =>
      props.stage >= 2 &&
      `
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
      padding-bottom: 50px;
      `}
  }
`

export const PollStageContentWrapper = styled.div``

export const PollStageColCalculation = styled(Col)`
  margin-right: -105px;
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-right: -65px;
  }
  @media (max-width: 991px) {
    /* ${(props) => !props.visibility && `display: none;`} */
  }
  @media (max-width: 575px) {
    display: none;
  }
`

export const PollStageColHeaderCalculationMobile = styled(Col)`
  @media (min-width: 992px) {
    display: none;
  }
`

export const PollStageCalculationWrapper = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.colorWhite};
  /* padding-top: 40px; */
  padding-left: 50px;
  padding-right: 15px;
  margin-right: -15px;
  border-top-left-radius: 20px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 35px;
    margin-right: 25px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    /* padding-top: 35px; */
    padding-left: 35px;
    margin-right: -15px;
  }
  @media (max-width: 991px) {
    margin-right: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    /* padding-top: 35px; */
    padding-left: 20px;
    margin-right: 0;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    /* padding-top: 35px; */
    padding-left: 20px;
    margin-right: 0;
  }
  @media (max-width: 575px) {
    /* padding-top: 35px; */
    padding-left: 15px;
    margin-right: 0;
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    padding-top: 40px;
    @media (min-width: 1200px) and (max-width: 1599px) {
      padding-top: 35px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      padding-top: 35px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-top: 35px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      padding-top: 35px;
    }
    @media (max-width: 575px) {
      display: none;
      padding-top: 25px;
    }
  }
  *:last-child {
    padding-bottom: 20px;
    @media (max-width: 575px) {
      padding-bottom: 0;
    }
  }
`

export const PollStageCalculationListDesc = styled.ul`
  counter-reset: listCounter;
  list-style-type: none;
  padding-left: 60px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 55px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 55px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 55px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 25px;
  }
  @media (max-width: 575px) {
    padding-left: 0;
    margin-bottom: 20px;
  }
  li {
    position: relative;
    font-size: 18px;
    margin-bottom: 40px;
    @media (min-width: 1200px) and (max-width: 1599px) {
      font-size: 18px;
      margin-bottom: 40px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 18px;
      margin-bottom: 40px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 18px;
      margin-bottom: 30px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 16px;
      margin-bottom: 20px;
    }
    @media (max-width: 575px) {
      font-size: 13px;
      /* margin-bottom: 15px; */
      margin-bottom: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &:before {
      counter-increment: listCounter;
      content: counter(listCounter);
      position: absolute;
      top: 0;
      left: -60px;
      color: #e1461a;
      font-size: 49px;
      font-weight: 700;
      line-height: 0.9;
      @media (min-width: 1200px) and (max-width: 1599px) {
        font-size: 41px;
        left: -50px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 41px;
        left: -50px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 41px;
        left: -50px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 25px;
        top: 3px;
        left: -30px;
      }
      @media (max-width: 575px) {
        position: static;
        display: block;
        font-size: 22px;
        top: 3px;
        left: -25px;
        padding-bottom: 8px;
      }
    }
    .title {
      font-weight: 700;
    }
    .title-value {
      color: #e1461a;
      font-weight: 700;
    }
  }
`

export const StageContainer = styled(MainContainer)`
  background-color: ${(props) => props.theme.colorPeach};
  @media (max-width: 575px) {
    padding-right: 45px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  &.firstStage {
    @media (max-width: 575px) {
      padding-right: 15px;
    }
  }
`

export const CollapseWrapper = styled.div``

export const CollapseTitle = styled.h3`
  color: ${(props) => props.theme.textColorGrayBlack};
  font-size: 29px;
  font-weight: 700;
  padding-top: 15px;
  padding-left: 0;
  padding-right: 6.9%;
  padding-bottom: 15px;
  margin-bottom: 0;
  cursor: pointer;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 24px;
    padding-top: 12px;
    padding-left: 0;
    padding-right: 4.9%;
    padding-bottom: 12px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 24px;
    padding-top: 12px;
    padding-left: 0;
    padding-right: 4.9%;
    padding-bottom: 12px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 24px;
    padding-top: 12px;
    padding-left: 0;
    padding-right: 4.9%;
    padding-bottom: 12px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px;
    padding-top: 12px;
    padding-left: 0;
    padding-right: 4.9%;
    padding-bottom: 12px;
  }
  @media (max-width: 575px) {
    font-size: 22px;
    padding-top: 12px;
    padding-left: 0;
    padding-right: 4.9%;
    padding-bottom: 12px;
  }
`

export const CollapseTitleWrapper = styled.span`
  position: relative;
  display: inline-block;
  padding-right: 25px;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 0;
    height: 0;
    margin-top: -4px;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    ${(props) =>
      props.dataOpen === true ? 'border-bottom' : 'border-top'}: 9px solid
      ${(props) => props.theme.colorOrangeRed};
    @media (min-width: 1200px) and (max-width: 1599px) {
      margin-top: -3px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      ${(props) =>
        props.dataOpen === true ? 'border-bottom' : 'border-top'}: 7px solid
        ${(props) => props.theme.colorOrangeRed};
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-top: -3px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      ${(props) =>
        props.dataOpen === true ? 'border-bottom' : 'border-top'}: 7px solid
        ${(props) => props.theme.colorOrangeRed};
    }
    @media (min-width: 768px) and (max-width: 991px) {
      margin-top: -3px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      ${(props) =>
        props.dataOpen === true ? 'border-bottom' : 'border-top'}: 7px solid
        ${(props) => props.theme.colorOrangeRed};
    }
    @media (min-width: 576px) and (max-width: 767px) {
      margin-top: -3px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      ${(props) =>
        props.dataOpen === true ? 'border-bottom' : 'border-top'}: 7px solid
        ${(props) => props.theme.colorOrangeRed};
    }
    @media (max-width: 575px) {
      margin-top: -3px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      ${(props) =>
        props.dataOpen === true ? 'border-bottom' : 'border-top'}: 7px solid
        ${(props) => props.theme.colorOrangeRed};
    }
  }
`

export const CollapseCard = styled(Card)`
  border: none;
`

export const CollapseCardBody = styled(CardBody)`
  padding-top: 1px;
  padding-left: 6.9%;
  padding-right: 6.9%;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 14px;
    padding-left: 4.9%;
    padding-right: 4.9%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
    padding-left: 4.9%;
    padding-right: 4.9%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
    padding-left: 4.9%;
    padding-right: 4.9%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 14px;
    padding-left: 4.9%;
    padding-right: 4.9%;
  }
  @media (max-width: 575px) {
    font-size: 14px;
    padding-left: 4.9%;
    padding-right: 4.9%;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }

    sup {
      padding-right: 5px;
    }
  }
`

export const DownloadLink = styled.a`
  position: relative;
  display: inline-block;
  background-color: #ffc621;
  color: rgb(70, 23, 13);
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
  padding-top: 20px;
  padding-right: 75px;
  padding-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0px 20px 48px 0px rgba(68, 11, 0, 0.15);
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 22px;
    padding-top: 17px;
    padding-right: 65px;
    padding-bottom: 17px;
    border-radius: 18px;
    box-shadow: 0px 16px 40px 0px rgba(68, 11, 0, 0.15);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 20px;
    padding-top: 15px;
    padding-right: 55px;
    padding-bottom: 15px;
    border-radius: 16px;
    box-shadow: 0px 14px 35px 0px rgba(68, 11, 0, 0.15);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 22px;
    padding-top: 17px;
    padding-right: 65px;
    padding-bottom: 17px;
    border-radius: 18px;
    box-shadow: 0px 16px 40px 0px rgba(68, 11, 0, 0.15);
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px;
    padding-top: 17px;
    padding-right: 65px;
    padding-bottom: 17px;
    border-radius: 18px;
    box-shadow: 0px 16px 40px 0px rgba(68, 11, 0, 0.15);
  }
  @media (max-width: 575px) {
  }
  &:hover,
  &:focus {
    color: rgb(70, 23, 13);
    opacity: 0.8;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 75px;
    background-color: #eeb100;
    background-image: url(${downloadIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    @media (min-width: 1200px) and (max-width: 1599px) {
      width: 65px;
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 55px;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 65px;
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      width: 65px;
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }
    @media (max-width: 575px) {
    }
  }
  span {
    display: inline-block;
    padding-left: 50px;
    padding-right: 25px;
    @media (min-width: 1200px) and (max-width: 1599px) {
      padding-left: 45px;
      padding-right: 20px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      padding-left: 35px;
      padding-right: 15px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-left: 45px;
      padding-right: 20px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      padding-left: 45px;
      padding-right: 20px;
    }
    @media (max-width: 575px) {
    }
  }
`
