import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../fonts/fonts.css'

const theme = {
  textColor: '#000',
  textColorWhite: '#fff',
  textColorBlack: '#000',
  textColorBlackLight: '#3d3d3d',
  textColorGrayBlack: '#2e2d2b',
  textColorCherry: '#a34544',
  textColorOrangeRed: '#e1461a',

  colorWhite: '#fff',
  colorBlack: '#000',
  colorPeach: '#fcdfcd',
  colorCherry: '#a34544',
  colorCherryBlack: '#8c373a',
  colorOrange: '#ffb800',
  colorOrangeDark: '#f8ae01',
  colorOrangeRed: '#e1461a',

  fontOpenSans: `"Open Sans", sans-serif;`,
  fontImpact: `"Impact", sans-serif;`,
}

const GlobalStyle = createGlobalStyle`

  html,
  body {
    height: 100%;
  }
  body {
    display: flex;
    flex-direction: column;
    position: relative;
    color: ${theme.textColor};
    font-size: 16px;
    font-family: ${theme.fontOpenSans};
    font-weight: normal;
    @media (max-width: 575px) {
      font-size: 14px;
    }
    .modal {
      top: 100px;
      @media (max-width: 360px) {
        top: 85px;
      }
    }
    .modal-backdrop {
      background-color: transparent;
    }
    .modalInfoBody {
      margin: 0;
      .modal-content {
        border: none;
        border-radius: 0;
      }
    }
  }

  a {
    transition: ease 0.2s all;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .container {
    @media (min-width: 1600px) {
      max-width: 1580px;
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      max-width: 1090px;
    }
  }
`

const Layout = (props) => {
  useEffect(() => {
    if (process.browser) {
      window.dataLayer = window.dataLayer || []
    }
  }, [])

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
